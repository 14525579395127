import AppBar from '@mui/material/AppBar';
import React, {useContext} from "react"
import {
  Box,
  Button, Divider, MenuItem,
  Typography,
} from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import {MainDataContext} from "../../main";
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import {ReactComponent as LogoWhite} from "../../../resources/img/Logo-white.svg";
import {Person as PersonIcon} from "@mui/icons-material";

const pages = [];

function appBarHeader() {
  const {
    state,
    setUser,
    setSessionStartTime,
// eslint-disable-next-line react-hooks/rules-of-hooks
} = useContext(MainDataContext)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [personEl, setPersonEl] = React.useState();


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const onOpenPersonMenu = (e) => {
    setPersonEl(e.currentTarget)
 }

 const onClosePersonMenu = () => {
    setPersonEl(null)
 }

 const onLogout = () => {
    setUser(null)
    setSessionStartTime(null)
 }
  return (
    <AppBar position="static" style={{ background: 'linear-gradient(to right bottom, #d8111a, #6e2181)'}}>
      <Container maxWidth="100%">
        <Toolbar disableGutters style={{justifyContent:"space-between"}}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <LogoWhite />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
              <IconButton style={{background:'#b0a6a6'}} onClick={onOpenPersonMenu}>
                  <PersonIcon />
              </IconButton>
              <Menu
                  open={personEl}
                  anchorEl={personEl}
                  onClose={onClosePersonMenu}
              >
                  <Typography style={{textAlign: 'center',
                      color: '#848383',
                      marginBottom: '0.4rem',
                      fontSize: '12px'}}>{state.user?.NAME}</Typography>
                  <Divider />
                  <MenuItem onClick={onLogout}>ログアウト</MenuItem>
              </Menu> 
          </Box>
           
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default appBarHeader;
