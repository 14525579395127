import React, { useContext,useState } from 'react';
import {
    Box,
    Button,
    Typography,
    IconButton,
} from "@mui/material";
import { MainDataContext } from '../../../main';
import {MapDataContext} from "../index";
import Divider from '@mui/material/Divider';
import {Visibility as VisibilityIcon} from "@mui/icons-material";
import {VisibilityOff as VisibilityIconOff} from "@mui/icons-material";
import {Check as CheckIcon} from "@mui/icons-material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { width } from 'cli-color/window-size';
import { center } from 'turf';
export const SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED = "SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED"
export const SHOW_TABELOG_POINT_TYPE_HANA_MATCH_FLAG = "SHOW_TABELOG_POINT_TYPE_HANA_MATCH_FLAG"
export const SHOW_TABELOG_POINT_TYPE_SAKAYA_MATCH_FLAG = "SHOW_TABELOG_POINT_TYPE_SAKAYA_MATCH_FLAG"
export const SHOW_TABELOG_POINT_TYPE_NSR_PRED = "SHOW_TABELOG_POINT_TYPE_NSR_PRED"
export const SHOW_TABELOG_POINT_TYPE_THE_OTHERS = "SHOW_TABELOG_POINT_TYPE_THE_OTHERS"

const styles = {
    TabelogLegendBox: {
        position: 'absolute',
        bottom: '3rem',
        left: '1rem',
        zIndex: '100',
        background: 'white',
        padding: '4px 8px',
        borderRadius: '8px',
        boxShadow: 'rgb(0, 0, 0) 1px 1px 3px'
    },
    legendButton: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'left',
        padding: 3,
        backgroundColor: 'transparent',
        lineHeight: 1.5,
        border: "1px solid #D4D8E1",
        borderRadius: '8px',
        width:180,
        margin:3,
    },
    legendBox: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center',
        padding: 3,
        backgroundColor: 'transparent',
        lineHeight: 1.5
    },
    legendsText: {
        fontSize: "12px"
    },
    colorCircle: {
        width: "10px",
        height: "10px",
        borderRadius: "50%"
    }
};

export const TabelogPointLegend = () => {

    const {
        state,
        setShowTabelogPointType,
    } = useContext(MainDataContext);

    const [minimize, setMinimize] = useState(true);

    const { state:mapState, setShowPointLayer } = useContext(MapDataContext)

    const handleOnClick = (event) => {
        event.stopPropagation()
        if (state.showTabelogPointType !== event.currentTarget.id) {
            setShowTabelogPointType(event.currentTarget.id)
        } else {
            setShowTabelogPointType(null)
        }
    }

    const getColor = (type) => {
        if (state.showTabelogPointType === null || state.showTabelogPointType === type) {
            return "black"
        }
        return "gray"
    }
    const getState = (type) => {
        if (state.showTabelogPointType === null || state.showTabelogPointType === type) {
            return true;
        }
        return false;
    }
    const isDisplayAll = () => {
        return getState(SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED) 
        && getState(SHOW_TABELOG_POINT_TYPE_SAKAYA_MATCH_FLAG)
        && getState(SHOW_TABELOG_POINT_TYPE_SAKAYA_MATCH_FLAG)
        && getState(SHOW_TABELOG_POINT_TYPE_THE_OTHERS)
        && getState(SHOW_TABELOG_POINT_TYPE_HANA_MATCH_FLAG);    
    }
    return (
         
        (minimize ?
        <Box style={styles.TabelogLegendBox}>
            <Box sx={{ display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <Typography style={{fontSize:"0.9rem"}}>Filter By Status</Typography>     
                <IconButton onClick={() => {setShowPointLayer(!mapState.showPointLayer)}} style={{marginLeft: "1rem", padding:2, color:"#1675d1"}}>
                     {mapState.showPointLayer?<VisibilityIcon />:<VisibilityIconOff />}
                </IconButton>        
                <IconButton onClick={()=>{setMinimize(false)}} style={{marginLeft: "1rem", padding:2}}>
                    <ArrowDropUpIcon/>  
                </IconButton>      

            </Box>
            {isDisplayAll()?
            <>
            <Button sx={{  justifyContent:"space-between"}} id={SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED} style={styles.legendButton} mb={0.2}  disableRipple>
            <Box style={styles.legendBox} sx={{  justifyContent:"flex-start"}}>
                <Box style={{...styles.colorCircle, background: "#818EA5"}} />
                <Box style={{...styles.legendsText, color: getColor(SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED)}} ml={1}>Selected All</Box>
            </Box>
                 <CheckIcon style={{width:20}}/>
            </Button>
            </>:
            <>{getState(SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED)&&
                <Button sx={{  justifyContent:"space-between"}} id={SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED} style={styles.legendButton} mb={0.2} onClick={handleOnClick} disableRipple>
                    <Box style={styles.legendBox} sx={{  justifyContent:"flex-start"}}>
                    <Box style={{...styles.colorCircle, background: "#818EA5"}} />
                    <Box style={{...styles.legendsText, color: getColor(SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED)}} ml={1}>新規(SFA登録済み)</Box>
                    </Box>
                    {getState(SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED)&&<CheckIcon style={{width:20}}/>}
                </Button>}
                {getState(SHOW_TABELOG_POINT_TYPE_HANA_MATCH_FLAG)&&
                <Button sx={{  justifyContent:"space-between"}} id={SHOW_TABELOG_POINT_TYPE_HANA_MATCH_FLAG} style={styles.legendButton} mb={0.2} onClick={handleOnClick} disableRipple>
                <Box style={styles.legendBox} sx={{  justifyContent:"flex-start"}}>
                    <Box style={{...styles.colorCircle, background: "#FF7E6F"}} />
                    <Box style={{...styles.legendsText, color: getColor(SHOW_TABELOG_POINT_TYPE_HANA_MATCH_FLAG)}} ml={1}>既存(直取)</Box>
                    </Box>
                    {getState(SHOW_TABELOG_POINT_TYPE_HANA_MATCH_FLAG)&&<CheckIcon style={{width:20}}/>}
                </Button>}
                {getState(SHOW_TABELOG_POINT_TYPE_SAKAYA_MATCH_FLAG)&&
                <Button sx={{  justifyContent:"space-between"}} id={SHOW_TABELOG_POINT_TYPE_SAKAYA_MATCH_FLAG} style={styles.legendButton} mb={0.2} onClick={handleOnClick} disableRipple>
                <Box style={styles.legendBox} sx={{  justifyContent:"flex-start"}}>
                    <Box style={{...styles.colorCircle, background: "#C877DB"}} />
                    <Box style={{...styles.legendsText, color: getColor(SHOW_TABELOG_POINT_TYPE_SAKAYA_MATCH_FLAG)}} ml={1}>既存(酒屋経由)</Box>
                    </Box>
                    {getState(SHOW_TABELOG_POINT_TYPE_SAKAYA_MATCH_FLAG)&&<CheckIcon style={{width:20}}/>}
                </Button>}
                {getState(SHOW_TABELOG_POINT_TYPE_NSR_PRED)&&
                <Button sx={{  justifyContent:"space-between"}} id={SHOW_TABELOG_POINT_TYPE_NSR_PRED} style={styles.legendButton} mb={0.2} onClick={handleOnClick} disableRipple>
                <Box style={styles.legendBox} sx={{  justifyContent:"flex-start"}}>
                    <Box style={{...styles.colorCircle, background: "#5FB0FF"}} />
                    <Box style={{...styles.legendsText, color: getColor(SHOW_TABELOG_POINT_TYPE_NSR_PRED)}} ml={1}>新規(ハイポテンシャル)</Box>
                    </Box>
                    {getState(SHOW_TABELOG_POINT_TYPE_NSR_PRED)&&<CheckIcon style={{width:20}}/>}
                </Button>}
                {getState(SHOW_TABELOG_POINT_TYPE_THE_OTHERS)&&
                <Button sx={{  justifyContent:"space-between"}} id={SHOW_TABELOG_POINT_TYPE_THE_OTHERS} style={styles.legendButton} mb={0.2} onClick={handleOnClick} disableRipple>
                    <Box style={styles.legendBox} sx={{  justifyContent:"flex-start"}}>
                        <Box style={{...styles.colorCircle, background: "#FBBA00"}} />
                        <Box style={{...styles.legendsText, color: getColor(SHOW_TABELOG_POINT_TYPE_THE_OTHERS)}} ml={1}>新規(その他)</Box>
                    </Box>
                    {getState(SHOW_TABELOG_POINT_TYPE_THE_OTHERS)&&<CheckIcon style={{width:20}}/>}
                </Button>}</>}  
        </Box>
        :
        <Box style={styles.TabelogLegendBox}>
            <Box sx={{ display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <Typography style={{fontSize:"0.9rem"}}>Filter By Status</Typography>     
                <IconButton onClick={()=>{setMinimize(true)}} style={{marginLeft: "1rem", padding:2}}>
                    <ArrowDropDownIcon/>  
                </IconButton>        
            </Box>
            <Button sx={{  justifyContent:"space-between"}} id={SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED} style={styles.legendButton} mb={0.2} onClick={handleOnClick} disableRipple>
            <Box style={styles.legendBox} sx={{  justifyContent:"flex-start"}}>
                <Box style={{...styles.colorCircle, background: "#818EA5"}} />
                <Box style={{...styles.legendsText, color: getColor(SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED)}} ml={1}>新規(SFA登録済み)</Box>
                </Box>
                {getState(SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED)&&<CheckIcon style={{width:20}}/>}
            </Button>
            <Button sx={{  justifyContent:"space-between"}} id={SHOW_TABELOG_POINT_TYPE_HANA_MATCH_FLAG} style={styles.legendButton} mb={0.2} onClick={handleOnClick} disableRipple>
            <Box style={styles.legendBox} sx={{  justifyContent:"flex-start"}}>
                <Box style={{...styles.colorCircle, background: "#FF7E6F"}} />
                <Box style={{...styles.legendsText, color: getColor(SHOW_TABELOG_POINT_TYPE_HANA_MATCH_FLAG)}} ml={1}>既存(直取)</Box>
                </Box>
                {getState(SHOW_TABELOG_POINT_TYPE_HANA_MATCH_FLAG)&&<CheckIcon style={{width:20}}/>}
            </Button>
            <Button sx={{  justifyContent:"space-between"}} id={SHOW_TABELOG_POINT_TYPE_SAKAYA_MATCH_FLAG} style={styles.legendButton} mb={0.2} onClick={handleOnClick} disableRipple>
            <Box style={styles.legendBox} sx={{  justifyContent:"flex-start"}}>
                <Box style={{...styles.colorCircle, background: "#C877DB"}} />
                <Box style={{...styles.legendsText, color: getColor(SHOW_TABELOG_POINT_TYPE_SAKAYA_MATCH_FLAG)}} ml={1}>既存(酒屋経由)</Box>
                </Box>
                {getState(SHOW_TABELOG_POINT_TYPE_SAKAYA_MATCH_FLAG)&&<CheckIcon style={{width:20}}/>}
            </Button>
            <Button sx={{  justifyContent:"space-between"}} id={SHOW_TABELOG_POINT_TYPE_NSR_PRED} style={styles.legendButton} mb={0.2} onClick={handleOnClick} disableRipple>
            <Box style={styles.legendBox} sx={{  justifyContent:"flex-start"}}>
                <Box style={{...styles.colorCircle, background: "#5FB0FF"}} />
                <Box style={{...styles.legendsText, color: getColor(SHOW_TABELOG_POINT_TYPE_NSR_PRED)}} ml={1}>新規(ハイポテンシャル)</Box>
                </Box>
                {getState(SHOW_TABELOG_POINT_TYPE_NSR_PRED)&&<CheckIcon style={{width:20}}/>}
            </Button>
            <Button sx={{  justifyContent:"space-between"}} id={SHOW_TABELOG_POINT_TYPE_THE_OTHERS} style={styles.legendButton} mb={0.2} onClick={handleOnClick} disableRipple>
                <Box style={styles.legendBox} sx={{  justifyContent:"flex-start"}}>
                    <Box style={{...styles.colorCircle, background: "#FBBA00"}} />
                    <Box style={{...styles.legendsText, color: getColor(SHOW_TABELOG_POINT_TYPE_THE_OTHERS)}} ml={1}>新規(その他)</Box>
                </Box>
                {getState(SHOW_TABELOG_POINT_TYPE_THE_OTHERS)&&<CheckIcon style={{width:20}}/>}
            </Button>
            <Divider style={{padding:"2px 12px"}} />
            <Box style={{margin:5}}>
                <Button variant="outlined" style={{padding:"2px 12px"}} startIcon={mapState.showPointLayer?<VisibilityIcon />:<VisibilityIconOff />}  onClick={() => {setShowPointLayer(!mapState.showPointLayer)}}>{mapState.showPointLayer?"Hide All":"Show All"}</Button>
            </Box>
        </Box>
        )

    );
}