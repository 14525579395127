import React, {useCallback, useContext, useEffect, useState} from "react"
import {Box, Button, TextField, Typography} from "@mui/material";
import {First} from "../../manager/carto3";
import _ from "lodash";
import {MainDataContext} from "../main";
import InputAdornment from '@mui/material/InputAdornment';
import LockIcon from '@mui/icons-material/Lock';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {ReactComponent as LogoDark} from "../../resources/img/Logo-dark.svg";
const styles = {
    root: {
        margin: '0',
        padding: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        flexDirection:  'column'
    },
    baseBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        width: '295px',
        height: '374px',
        background: '#fff',
        border: '1px solid #ccc'
    },
    headerBox2: {
        display: 'flex',
        gap: '12px',
        width: '100%',
        height: '50%',
        justifyContent:'center',
        background: 'linear-gradient(to right bottom, #d8111a, #6e2181)'
    },
    headerBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        padding:'5px 5px 5px 5px',
        width: '100%',
        height: '50%',
        background: '#fff'
    },
    titleBox: {
        textAlign: "center",
    },
    titleText: {
    
    },
    idTextField: {

    },
    passwordTextField: {

    },
    buttonBox: {
        textAlign: "center",
    },
    loginButton: {

    }
}

const LoginView = (props) => {

    const { setUser, setSessionStartTime, setMapViewState, setShowTabelogPointType } = useContext(MainDataContext)
    const [id, setId] = useState()
    const [password, setPassword] = useState()
    const [isError, setIsError] = useState(false)
    const [hash, setHash] = useState()

    useEffect(() => {
        if (_.isEmpty(password)) {
            setHash(null)
        }
        const encoder = new TextEncoder()
        const uint8 = encoder.encode(password)
        crypto.subtle.digest('SHA-256', uint8)
            .then(buf => {
                const arr = Array.from(new Uint8Array(buf))
                const hex = arr.map(b => b.toString(16).padStart(2, '0')).join('')
                setHash(hex)
            })
    }, [password])

    const onLogin = useCallback(() => {
        console.log("id:pass:hash", id, password, hash)
        First(`
        SELECT ID,NAME,MAIL,H3_INDICES_LEVEL11,GEOMETRY FROM ${process.env.REACT_APP_CARTO_TABLE_SALES}
        WHERE ID = :1 AND PASSWORD = :2
        `, [
            id,
            hash,
        ]).then(user => {
            if (!user) {
                isError(true)
                return
            }
            setUser(user)
            setSessionStartTime(Date.now())
            setMapViewState(null)
            setShowTabelogPointType(null)
        }).catch(e => {
            setIsError(true)
        })
    }, [id, hash])

    const onChangeId = e => {
        setIsError(false)
        setId(e.target.value)
    }

    const onChangePassword = e => {
        setIsError(false)
        setPassword(e.target.value)
    }

    return (
        <Box style={styles.root}>
             <Box style={styles.headerBox}>
                <LogoDark  style={{padding:20}}/>
             </Box>
             <Box style={styles.headerBox2}>
                <Box style={styles.baseBox} sx={{ marginTop:'-187px', borderRadius:'15px'}}>
                    <Box style={{ display:'flex', justifyContent:'space-between', flexDirection:'column', height:'100%', padding:40}}>
                        <Box style={styles.titleBox}>
                            <Typography sx={{fontWeight:800, fontSize:'1.2rem'}} style={styles.titleText}>コカ・コーラ・販売管理</Typography>
                        </Box>
                        {isError && <Typography size="small" style={{color: "#f00"}}>社員番号かパスワードが間違っています</Typography>}
                        <Typography>社員番号</Typography>
                        <TextField value={id} onChange={onChangeId} size="small"
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <PersonOutlineIcon />
                              </InputAdornment>
                            ),
                          }}
                        style={styles.idTextField} variant="outlined"></TextField>
                        <Typography>パスワード</Typography>
                        <TextField value={password} onChange={onChangePassword} size="small" type="password"
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <LockIcon />
                              </InputAdornment>
                            ),
                          }}
                           style={styles.passwordTextField} variant="outlined"></TextField>
                        <Box style={styles.buttonBox}>
                            <Button style={styles.loginButton} variant="contained" onClick={onLogin}>ログイン</Button>
                        </Box>
                    </Box>
                   
                </Box>
            </Box>
        </Box>
    )

}

export default LoginView
