import React, {useContext, useEffect, useRef, useState} from "react"
import {Backdrop, Box, IconButton} from "@mui/material"
import HeaderView from "./header"
import AppBarHeaderView from "./header/appBar"
import {DataType, ViewDirection, ViewMode} from "../../data/main/state"
import {MainDataContext} from "../main"
import {RootMapView as MapView} from "./map"
import ListView from "./list"
import RightView from "./right"
import FilterView from "./filter"
import {Close as CloseIcon, DragHandle as DragHandleIcon} from "@mui/icons-material";
import AssignView from "./assign";
import GraphView from "./graph"
import {useResizable} from "react-resizable-layout";
import ListSalesView from "./list_sales";
import TabelogInfoView from "./info/tabelog";
import SalesInfoView from "./info/sales";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const styles = {
    root: {
        display: "flex",
        flexDirection: 'column',
        width: "100vw",
        height: "100vh",
    },
    rootContainer: {
        display: "flex",
        flexDirection: 'column',
        padding:0,
        width: "100%",
        height: "100%",
    },
    baseBox: {
        flexGlow: 1,
        display: "flex",
        width: "100%",
        height: "100%"
    },
    resizerBox: {
        background: '#eee',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'ns-resize',
        height: "16px",
    },
    backdropCloseButtonBox: {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        width: '60px',
        height: '60px',
        background: '#ffffff82',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '30px',
    },
    backDrop: {
        zIndex: 2000
    },
}


const RootView = () => {

    const { state, setGraphData, setIsDragging ,setFilterModel ,
        setTabelogInfo,setViewMode,
        setSalesInfo,} = useContext(MainDataContext)
    const [openAssignWindow, setOpenAssignWindow] = useState(false)
    const containerRef = useRef()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const myTheme = useTheme();
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const isTablet = useMediaQuery(myTheme.breakpoints.down('lg'));
   //  eslint-disable-next-line react-hooks/rules-of-hooks
   const isMobile = useMediaQuery(myTheme.breakpoints.down('sm'));

    const { setPosition, position, separatorProps} = useResizable({
        axis: 'y',
        containerRef,
        min: 100,
        max: window.innerHeight - 300,
        onResizeStart: () => { setIsDragging(true) },
        onResizeEnd: () => { setIsDragging(false) }
    })

    const onChangeViewMode = (event, values) => {
        setTabelogInfo(null)
        setSalesInfo(null)
        setFilterModel(null)
        if (values.length === 0) {
            return
        }
        setViewMode(values)
    }
    useEffect(() => {
        setPosition("50%")
    }, [])

    const handleDrag = () => {
        if(state.viewMode[0] === 'list')
        {
            onChangeViewMode(null,["map","list"]);
            setPosition("50%");
        }
         else
            onChangeViewMode(null,["list"]);
    }
    // useEffect(() => {
    //     if(position === 0)
    //     {
    //         if(state.viewMode[0] === 'list')
    //         {
    //             onChangeViewMode(null,["map","list"]);
    //             setPosition("50%");
    //         }
    //         else
    //             onChangeViewMode(null,["list"]);
    //     }
    //     else
    //     {
    //         setPosition("50%");
    //         onChangeViewMode(null,["map","list"]);
    //     }
    // }, [position])

    const theme = createTheme({
        typography: {
            fontFamily: 'Arial',
            fontSize: 14,
        },
        colorSchemes: {
          dark: true,
        },
      });

      const isShowFilterView = () =>
      {
        let isShow = false;
        let conditions = Object.entries(state.conditions);
        if(state.conditions && conditions.length > 0)
        {
            conditions.map((value, _) => {
                if(value.length>1 && value[1] != null) isShow = true;
            });
                
        }
        if(state.selectedTabelogIds.length > 0)
        {
            isShow = true;
        }
        return isShow;
      }

    return (
        <>
        <ThemeProvider theme={theme} >
                <Box style={styles.root}>
                    <AppBarHeaderView/>
                    <Box style={styles.rootContainer}>
                        <HeaderView  onAssign={() => setOpenAssignWindow(true)} />
                        {isShowFilterView() && <FilterView />}
                        <Box style={{display: "flex", flexGrow: 1, flexDirection: "row"}}>
                            <Box style={{...styles.baseBox, flexDirection: state.viewDirection === ViewDirection.Vertical ? "column": "row",}}
                                ref={containerRef}>
                                {state.viewMode.includes(ViewMode.Map) && <MapView height={(state.viewMode.includes(ViewMode.Map) && state.viewMode.includes(ViewMode.List)) ? position: "100%"} />}
                                
                                <Box  style={styles.resizerBox} {...separatorProps}>
                                    <DragHandleIcon onClick={()=> { handleDrag()}}  fontSize="12px" />
                                </Box>
                                
                                {state.viewMode.includes(ViewMode.List) && state.dataType === DataType.TABELOG && <ListView />}
                                {state.viewMode.includes(ViewMode.List) && state.dataType === DataType.SALES && <ListSalesView />}
                            </Box>
                            {state.viewMode.includes(ViewMode.Map) && state.viewMode.includes(ViewMode.Graph) && <RightView />}
                        </Box>
                        <TabelogInfoView />
                        <SalesInfoView />
                    </Box>
                </Box>
                <Backdrop style={styles.backDrop} open={openAssignWindow}>
                    <Box style={styles.backdropCloseButtonBox}>
                        <IconButton onClick={() => setOpenAssignWindow(false)}>
                            <CloseIcon size="large" />
                        </IconButton>
                    </Box>
                    {openAssignWindow && <AssignView onClose={() => setOpenAssignWindow(false)} />}
                </Backdrop>
                <Backdrop style={styles.backDrop} open={!!state.graphData}>
                    <GraphView onClose={() => setGraphData(null)} />
                </Backdrop>
        </ThemeProvider>
        </>
    )
}

export default RootView
