import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {vectorQuerySource, VectorTileLayer} from "@deck.gl/carto";
import PropTypes from "prop-types";
import {EVENT_KEY_UPDATE_GRID_STATE, DataType, LOCAL_STORAGE_KEY_FILTER_MODEL} from "../../../../data/main/state";
import {filterModelToWheres} from "../../../../manager/filter";
import {MainDataContext} from "../../../main";
import {One} from "../../../../manager/carto3";
import {MapDataContext} from "../index";
import _ from "lodash";
import {rgbA} from "../../../../util";
import { 
    SHOW_TABELOG_POINT_TYPE_HANA_MATCH_FLAG, 
    SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED, 
    SHOW_TABELOG_POINT_TYPE_NSR_PRED, 
    SHOW_TABELOG_POINT_TYPE_SAKAYA_MATCH_FLAG, 
    SHOW_TABELOG_POINT_TYPE_THE_OTHERS 
} from "../legend/tabelog_point";


const UseRootMapTabelogPointLayer = (props) => {

    const { state } = useContext(MainDataContext)
    const { state: mapState } = useContext(MapDataContext)
    const [filterModel, setFilterModel] = useState(null);
    const [layer, setLayer] = useState(null)

    useEffect(() => {
        window.addEventListener(EVENT_KEY_UPDATE_GRID_STATE, () => {
            const fm = localStorage.getItem(LOCAL_STORAGE_KEY_FILTER_MODEL)
            if (!fm) {
                setFilterModel(null)
                return
            }
            if (!filterModel || JSON.stringify(filterModel) !== fm) {
                setFilterModel(JSON.parse(fm))
            }
        })
    }, [])

    const checkCount = async (wheres, values) => {
        let cnt = await One(`
        SELECT COUNT(1)
        FROM ${process.env.REACT_APP_CARTO_TABLE_TABELOG_POINT} AS tabelog
        INNER JOIN ${process.env.REACT_APP_CARTO_TABLE_SALES} AS sales
        ON ST_CONTAINS(sales.geometry, tabelog.geom)        
        WHERE ${wheres.join(' AND ')}
        `, values)
        return cnt > 0
    }

    const getWheresByShowTabelogPointType = () => {
        const wheres = []

        if (state.showTabelogPointType === SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED) {
            wheres.push("IS_ASSIGNED = TRUE")
        
        } else if (state.showTabelogPointType === SHOW_TABELOG_POINT_TYPE_HANA_MATCH_FLAG) {
            wheres.push("IS_ASSIGNED = FALSE")
            wheres.push("HANA_MATCH_FLAG in ('○', '△')")
            wheres.push("SAKAYA_MATCH_FLAG not in ('○', '△')")
        
        } else if (state.showTabelogPointType === SHOW_TABELOG_POINT_TYPE_SAKAYA_MATCH_FLAG) {
            wheres.push("IS_ASSIGNED = FALSE")
            wheres.push("SAKAYA_MATCH_FLAG in ('○', '△')")
        
        } else if (state.showTabelogPointType === SHOW_TABELOG_POINT_TYPE_NSR_PRED) {
            wheres.push("IS_ASSIGNED = FALSE")
            wheres.push("HANA_MATCH_FLAG not in ('○', '△')")
            wheres.push("SAKAYA_MATCH_FLAG not in ('○', '△')")
            wheres.push("NSR_PRED = 'high'")
        
        } else if (state.showTabelogPointType === SHOW_TABELOG_POINT_TYPE_THE_OTHERS) {
            wheres.push("IS_ASSIGNED = FALSE")
            wheres.push("HANA_MATCH_FLAG not in ('○', '△')")
            wheres.push("SAKAYA_MATCH_FLAG not in ('○', '△')")
            wheres.push("NSR_PRED != 'high'")
        }

        return wheres
    } 

    const data = useMemo(async () => {
        let wheres = filterModelToWheres(state.filterModel)
        let values = []

        wheres.push(`SALES.ID = ${state.user?.ID}`)
        wheres.push(...getWheresByShowTabelogPointType())
        
        if (!mapState.showPointLayer || state.dataType !== DataType.TABELOG) {
            setTimeout(() => {
                props.onLoadFinish()
            }, 1000)
            return null
        }

        if (state.showSelectedOnly) {
            wheres.push(`TABELOG_ID IN ('${state.selectedTabelogIds.join("','")}')`)
        }

        if (!(await checkCount(wheres, values))) {
            setTimeout(() => {
                props.onLoadFinish()
            }, 1000)
            return null
        }

        const sqlQuery = `
        SELECT tabelog.*, 1 as d FROM ${process.env.REACT_APP_CARTO_TABLE_TABELOG_POINT} AS tabelog
        INNER JOIN ${process.env.REACT_APP_CARTO_TABLE_SALES} AS sales
        ON ST_CONTAINS(sales.geometry, tabelog.geom)
        WHERE ${wheres.join(' AND ')}        
        `

    //    console.log('[TabelogPoint]', 'query', sqlQuery, values)

        return vectorQuerySource({
            accessToken: process.env.REACT_APP_CARTO_ACCESS_TOKEN,
            connectionName: 'snowflakeconn',
            apiBaseUrl: 'https://gcp-asia-northeast1.api.carto.com',
            sqlQuery: sqlQuery,
            // queryParameters: values,
        });
    }, [
        state.filterModel,
        state.showSelectedOnly,
        state.selectedTabelogIds,
        state.dataType,
        state.user,
        state.showTabelogPointType,
        mapState.showPointLayer,
    ]);

    const getFillColor = useCallback((p) => {
        if (state.selectedTabelogIds.includes(p.properties["TABELOG_ID"])) {
            return [0,0,255]
        }

        if (JSON.parse(p.properties["IS_ASSIGNED"])) {
            return rgbA("#818EA5")
        }
        if (!_.isEmpty(p.properties["SAKAYA_MATCH_FLAG"])) {
            return rgbA("#C877DB")
        }
        if (!_.isEmpty(p.properties["HANA_MATCH_FLAG"])) {
            return rgbA("#FF7E6F")
        }
        if (p.properties["NSR_PRED"] === "high") {
            return rgbA("#5FB0FF")
        }
        return rgbA("#FBBA00")
        // return [255,0,0]
    }, [state.selectedTabelogIds])

    useEffect(() => {
        props.onLoadStart && props.onLoadStart()
    }, [filterModel, state.startDate, state.endDate])

    useEffect(() => {
        data.then(d => {
            if (d) {
                setLayer(
                    new VectorTileLayer({
                        id: 'retail_stores',
                        data,
                        getFillColor,
                        getPointRadius: 2,
                        pointRadiusScale: 2,
                        pointRadiusUnits: "pixels",
                        getLineColor: [0,0,0,100],
                        getLineWidth: 0.1,
                        lineWidthScale: 1,
                        pickable: true,
                        onDataLoad: () => {
                        },
                        onViewportLoad: e => {
                            props.onLoadFinish && props.onLoadFinish()
                        },
                        onClick: props.onClick,
                        onHover: props.onHover,
                        updateTriggers: {
                            getFillColor: [state.selectedTabelogIds],
                        }
                    })
                )
            } else {
                setLayer(null)
            }
        })
    }, [data])

    return layer
}

UseRootMapTabelogPointLayer.propTypes = {
    onClick: PropTypes.func,
    onHover: PropTypes.func,
    onLoadStart: PropTypes.func,
    onLoadFinish: PropTypes.func,
}

export default UseRootMapTabelogPointLayer
