import React, {useContext, useMemo, useState} from "react"
import {
    Box,
    Button, Divider,
    FormControlLabel, MenuItem, Select,
    Switch,
    Typography,
} from "@mui/material";
import {MainDataContext} from "../../main";
import {DataType, DateMode} from "../../../data/main/state";
import { SendRounded as SendRoundedIcon} from "@mui/icons-material";
import PropTypes from "prop-types";
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0rem 0rem',
        paddingBottom: 10,
        background: '#fff',
    },
    spacer: {
        flexGrow: 1,
    },
    switchSelectedItemBox: {
        display: "flex",
        alignItems: "center"
    },
    switchSelectedItemText: {
        fontSize: "14px",
    },
    selectButton: {
        marginLeft: "0rem",
        marginRight:"0rem"
    },
    selectButtonTablet: {
        marginLeft: "0rem",
        marginRight:"0rem",
        marginTop:30
    },
    selectButtonText: {
        fontSize: "14px",
        marginRight: "8px",
    },
    endSpacer: {
        width: "1.8rem",
    },
}
const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#1890ff',
          ...theme.applyStyles('dark', {
            backgroundColor: '#177ddc',
          }),
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 15,
      height: 16,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 20 / 2,
      opacity: 1,
      backgroundColor: 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
      ...theme.applyStyles('dark', {
        backgroundColor: 'rgba(255,255,255,.35)',
      }),
    },
  }));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));
  
const RootHeaderView = (props) => { 
    const {
        state,
        setViewMode,
        setShowSelectedOnly,
        setUser,
        setDataType,
        setDateMode,
        setSalesInfo,
        setTabelogInfo,
        setFilterModel,
        setSessionStartTime,
    } = useContext(MainDataContext)
    const [personEl, setPersonEl] = useState()
    const modeValues = useMemo(() => state.viewMode, [state.viewMode])

    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        if(modeValues.length === 2)
        {
           let values =  {
             list:Object.entries(modeValues)[1] !== null &&  Object.entries(modeValues)[1] !== undefined ?  true : false,
             map: Object.entries(modeValues)[0] !== null  && Object.entries(modeValues)[0] !== undefined ? true: false,
          };
           setViewModeState(values);
        }
        else
        {
            let values =  {
                list:Object.entries(modeValues)[0][0] !== null &&  Object.entries(modeValues)[0][1] === 'list' ?  true : false,
                map: Object.entries(modeValues)[0][0] !== null  && Object.entries(modeValues)[0][1] === 'map' ? true: false,
             };
              setViewModeState(values);
        }

    },[state.viewMode]) 

    const onChangeViewMode = (event, values) => {
        setTabelogInfo(null)
        setSalesInfo(null)
        setFilterModel(null)
        if (values.length === 0) {
            return
        }
        setViewMode(values)
    }

    const onChangeDateMode = (e) => {
        setTabelogInfo(null)
        setSalesInfo(null)
        setFilterModel(null)
        setDateMode(e.target.value)
    }

    const handleChange = (event) => {

        let hasMap = displayMode.map;
        let hasList = displayMode.list;
        if(event.target.name === "map")
            hasMap = event.target.checked;
        if(event.target.name === "list")
            hasList = event.target.checked;

        if(!hasMap && !hasList) return;

        setViewModeState({
            ...displayMode,
            [event.target.name]: event.target.checked,
        });
        
        var displayViews = [];
        if(hasMap && hasList)
            displayViews = ["map","list"];
        else if (hasList)
            displayViews = ["list"];
        else
            displayViews = ["map"];

        onChangeViewMode(null,displayViews);
      };

    const [displayMode, setViewModeState] = React.useState({
        list:Object.entries(modeValues)[1] !== null &&  Object.entries(modeValues)[1] !== undefined ?  true : false,
        map: Object.entries(modeValues)[0] !== null  && Object.entries(modeValues)[0] !== undefined ? true: false,
      });

 
    return (
    <Box sx={{ width: '100%', fontSize:14 }}>
        <Stack spacing={0}>
            <Item>
                {!isMobile ?
                <>
                {isTablet ? <>
                <Box style={{paddingBottom:10, paddingTop:10, margin:0}} sx={{ fontWeight: 'bold', m: 1 , fontSize:18}}>Content and data display </Box>
                <Box style={styles.root} sx={{ gridColumn: 'span 12', justifyContent:"space-between"}}>
                
                 <Box style={{display: 'flow',
                            margin: '0 0rem',
                            paddingRight:20,
                            justifyContent: 'center',
                            alignItems: 'center',}}>
                     <Typography style={{paddingBottom:5, fontSize:14}}>表示データ種別</Typography>
                     <Select style={{width:157, height:32}} size="small" value={state.dataType} onChange={e => setDataType(e.target.value)}>
                            <MenuItem value={DataType.TABELOG}>食べログ</MenuItem>
                            <MenuItem value={DataType.SALES}>売上</MenuItem>
                     </Select>                          
                </Box>
                 
                 
                <Box style={{display: 'flow',
                            margin: '0 0rem',
                            paddingLeft:20,
                            paddingRight:20,
                            justifyContent: 'center',
                            alignItems: 'center',}}>
                    <Typography style={{paddingRight:10, paddingBottom:5}}>Collection period  </Typography>
                    <RadioGroup style={{paddingLeft:10}} value={state.dateMode}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group" onChange={onChangeDateMode}>
                        <FormControlLabel style={{ width:157, height :32, paddingRight:10, borderRadius: '14px', border: '1px solid #000'}} value={DateMode.Monthly} control={<Radio />} label="前月" />
                        <FormControlLabel style={{ width:157, height :32, borderRadius: '14px',border: '1px solid #000'}} value={DateMode.Yearly} control={<Radio />} label="過去12ヶ月"  />
                    </RadioGroup>
                </Box>
              
                <Button  variant="contained" style={styles.selectButtonTablet} disabled={!state.selectedList  ||  state.selectedList.length === 0|| state.dataType !== DataType.TABELOG} onClick={props.onAssign}>
                    <Typography style={styles.selectButtonText}>担当者指定</Typography>
                    <SendRoundedIcon size="small" />
                </Button>

                </Box>
                </> :
                <>
                <Box style={{paddingBottom:10, paddingTop:10, margin:0}} sx={{ fontWeight: 'bold', m: 1 , fontSize:18}}>Content and data display </Box>
                <Box style={styles.root} sx={{ gridColumn: 'span 12', justifyContent:"space-between"}}>
                  
                 <Box style={{display: 'flex',
                            margin: '0 0rem',
                            paddingRight:20,
                            justifyContent: 'center',
                            alignItems: 'center',}}>
                    <Typography sx={{paddingRight:2}}>表示データ種別 </Typography>
                    <Select style={{width:157,height:32}} size="small" value={state.dataType} onChange={e => setDataType(e.target.value)}>
                            <MenuItem value={DataType.TABELOG}>食べログ</MenuItem>
                            <MenuItem value={DataType.SALES}>売上</MenuItem>
                    </Select>
                 </Box>
                 
                <Divider style={{color:'black'}} orientation="vertical" variant="middle" flexItem />

                <Box style={{display: 'flex',
                            margin: '0 0rem',
                            paddingLeft:20,
                            paddingRight:20,
                            justifyContent: 'center',
                            alignItems: 'center',}}>
                    <Typography style={{paddingRight:10}}>Collection period </Typography>
                    <RadioGroup style={{paddingLeft:10}} value={state.dateMode}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group" onChange={onChangeDateMode}>
                         <FormControlLabel style={{ width:157, height :32, paddingRight:10, borderRadius: '14px', border: '1px solid #000'}} value={DateMode.Monthly} control={<Radio />} label="前月" />
                         <FormControlLabel style={{ width:157, height :32, borderRadius: '14px',border: '1px solid #000'}} value={DateMode.Yearly} control={<Radio />} label="過去12ヶ月"  />
                    </RadioGroup>
                </Box>

                <Divider style={{color:'black'}} orientation="vertical" variant="middle" flexItem />
                
                <Box style={{display: 'flex',
                            margin: '0 0rem',
                            paddingLeft:20,
                            paddingRight:20,
                            justifyContent: 'center',
                            alignItems: 'center',}}>
                        <Typography style={{paddingRight:10}}>View Options </Typography>
                        <Stack direction="row" spacing={1} sx={{ alignItems: 'center', paddingRight:2 }}>
                            <AntSwitch name="list"  checked={displayMode.list} onChange={handleChange}  defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                            <Typography>リスト</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                            <AntSwitch name="map" checked={displayMode.map} onChange={handleChange}   defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                            <Typography>地図</Typography>
                        </Stack>
                </Box>                   
                <Button variant="contained" style={styles.selectButton} disabled={!state.selectedList  ||  state.selectedList.length === 0 || state.dataType !== DataType.TABELOG} onClick={props.onAssign}>
                    <Typography style={styles.selectButtonText}>担当者指定</Typography>
                    <SendRoundedIcon size="small" />
                </Button>
                </Box>
                </>
                }</> :
                <>

                <Box style={{paddingBottom:10, paddingTop:10, margin:0}} sx={{ fontWeight: 'bold', m: 1 , fontSize:18}}>Content and data display </Box>
                <Box style={styles.root} sx={{ gridColumn: 'span 12', justifyContent:"space-between"}}>
                
                 <Box style={{display: 'flow',
                            margin: '0 0rem',
                            paddingRight:0,
                            justifyContent: 'center',
                            alignItems: 'center',}}>
                     <Typography style={{paddingBottom:5, fontSize:14}}>表示データ種別</Typography>
                     <Select style={{width:120, height:26}} size="small" value={state.dataType} onChange={e => setDataType(e.target.value)}>
                            <MenuItem value={DataType.TABELOG}>食べログ</MenuItem>
                            <MenuItem value={DataType.SALES}>売上</MenuItem>
                     </Select>                          
                </Box>
                 
                 
                <Box style={{display: 'flow',
                            margin: '0 0rem',
                            paddingLeft:20,
                            paddingRight:20,
                            justifyContent: 'center',
                            alignItems: 'center',}}>
                    <Typography style={{paddingRight:10, paddingBottom:5}}>Collection period </Typography>
                    <RadioGroup style={{paddingLeft:10}} value={state.dateMode}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group" onChange={onChangeDateMode}>
                        <FormControlLabel style={{ width:100, height :26, marginBottom:5, borderRadius: '14px', border: '1px solid #000'}} value={DateMode.Monthly} control={<Radio />} label="前月" />
                        <FormControlLabel style={{ width:140, height :26, borderRadius: '14px',border: '1px solid #000'}} value={DateMode.Yearly} control={<Radio />} label="過去12ヶ月"  />
                    </RadioGroup>
                </Box>
              
                <Button  variant="contained" sx={{height:24}} style={styles.selectButtonTablet} disabled={!state.selectedList ||  state.selectedList.length === 0|| state.dataType !== DataType.TABELOG} onClick={props.onAssign}>
                    <Typography style={styles.selectButtonText}></Typography>
                    <SendRoundedIcon size="small" />
                </Button>

                </Box>
                
                </>
             }
            </Item>
        </Stack>
    </Box>)

}

RootHeaderView.propTypes = {
    onAssign: PropTypes.func.isRequired,
}

export default RootHeaderView
