import React, {useContext,useState} from "react"
import PropTypes from "prop-types";
import {MapDataContext} from "../index";
import {Box, IconButton, Typography} from "@mui/material";
import {Adjust, ExpandCircleDown} from "@mui/icons-material";

const styles = {
    root: {
        border: '1px solid #808080',
        boxShadow: '1px 1px 3px #868686',
        borderRadius: '8px',
        padding: '12px',
        display: 'flex',
        flexDirection: 'column',
        margin: "8px 0",
    },
    base: {
        display: "flex",
        flexDirection: 'row',
        gap: '8px',
        cursor: "pointer",
        height: "16px",
        alignItems: "center",
    },
}

const RootMapConditionBaseView = React.memo(({type, title, text, children, unit}) => {
const [open, setOpen] = useState(false)

const { state, toggleSelectedConditionType, toggleVisibleConditionType } = useContext(MapDataContext)

    return (
        <Box style={{
            ...styles.root,
            backgroundColor: state.selectedConditionType === type ? "#fff" : "#ffffffcc",
            width: state.selectedConditionType === type ? "600px": "250px",
        }}>
            <Box style={styles.base} sx={{justifyContent:"space-between"}} onClick={() => {toggleSelectedConditionType(type); setOpen(!open);}}>
                <Box>
                <Typography style={{fontSize: "14px", fontWeight: "bold"}}>{title}</Typography>
                {state.selectedConditionType !== type && (
                    <Typography style={{
                        ...styles.description,
                        color: text ? "black": "#333",
                        fontSize: "12px",
                    }}>
                        {text ?? "未設定"}
                    </Typography>
                )}
                </Box>
                <Box>
                    <IconButton style={{padding:2}} onClick={e => {
                        if (state.selectedConditionType !== type) {
                            toggleSelectedConditionType(type)
                        } else {
                            toggleVisibleConditionType(type)
                        }
                        e.stopPropagation()
                    }}>
                        <Adjust
                            size="small"
                            style={{color: state.visibleConditionType === type ? "#575757cc": "#d8d8d8"}}
                        />
                    </IconButton>
                    <IconButton style={{padding:2}} onClick={e => {setOpen(!open)}}>
                        {open ?
                        <ExpandCircleDown
                        size="small"
                        style={{color: "#575757cc", rotate:"180deg"}}
                        />:
                        <ExpandCircleDown
                            size="small"
                            style={{color:"#575757cc" }}
                        />
                        }
                        
                    </IconButton>
                </Box>
            </Box>
            {state.selectedConditionType === type && children}
        </Box>
    )
})

RootMapConditionBaseView.propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    children: PropTypes.node.isRequired,
    unit: PropTypes.string,
}

export default RootMapConditionBaseView
