import {Box, Button} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import LOCALE_JA from "../../../resources/aggrid/locale.ja";
import {defaultColDef} from "../list/column";
import {ColumnDefs} from "./column";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";

import {
    LOCAL_STORAGE_KEY_COLUMN_DEFS,
    LOCAL_STORAGE_KEY_COLUMN_STATE,
} from "../../../data/main/state";
import {MainDataContext} from "../../main";
import {booleanFilterParams, filterModelToWheres, UseDistinctFilterParams} from "../../../manager/filter";
import _ from "lodash";
import {One, Rows} from "../../../manager/carto3";

const LocalStorageKeyColumnDefs = `${LOCAL_STORAGE_KEY_COLUMN_DEFS}_sales`
const LocalStorageKeyColumnState = `${LOCAL_STORAGE_KEY_COLUMN_STATE}_sales`

const styles = {
    root: {
        flexGrow: 1,
        display: "flex",
        flexDirection: 'column',
    },
    controlBox: {
        height: '28px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        alignItems: 'center',
        marginRight: '8px',
        margin: '0.5rem',
        gap: '8px',
    },
    listBox: {
        flexGrow: 1,
    },
}

const RootListSalesView = (props) => {

    const { state, setFilterModelSales, setRemoveFilter } = useContext(MainDataContext)
    const apiRef = useRef()
    const gridRef = useRef()
    const [gridReady, setGridReady] = useState()
    const [rowCount, setRowCount] = useState()

    const distinctFilterParams = UseDistinctFilterParams()

    useEffect(() => {
        if (state.removeFilter) {
            if (apiRef.current) {
                const filterModel = apiRef.current.getFilterModel()
                delete filterModel[state.removeFilter]
                apiRef.current.setFilterModel(filterModel)
                setRemoveFilter(null)
            }
        }
    }, [state.removeFilter])

    const getRowCount = _.debounce((wheres) => {
        One(`
        SELECT COUNT(1) 
        FROM ${process.env.REACT_APP_CARTO_TABLE_SALES_POINT} AS point
        INNER JOIN (SELECT ID AS SALES_ID, GEOMETRY FROM ${process.env.REACT_APP_CARTO_TABLE_SALES}) AS sales
        ON ST_CONTAINS(sales.geometry, point.geom)
        WHERE ${wheres.join(' AND ')}
        `).then(cnt => {
            console.log(cnt.toLocaleString())
            setRowCount(cnt)
        })

    }, 500)

    const dataSource = useMemo(() => {
        return {
            rowCount,
            getRows: (e) => {
                let orders = ['ZJDLRC']
                let wheres = ["TRUE"]

                wheres.push(`SALES_ID = ${state.user?.ID}`)
                wheres.push('GEOM_GEOHASH is not null')

                if (e.sortModel?.length) {
                    orders = e.sortModel.map(s => `${s.colId} ${s.sort}`)
                }

                if (e.filterModel) {
                    wheres = [...wheres, ...filterModelToWheres(e.filterModel)]
                }

                wheres.push(`H3 IN ('${state.selectedH3List.join("','")}')`)
                
                getRowCount(wheres)

                Rows(`
                SELECT
                    ZJDLRC,
                    ZJDLRC___T,
                    ZJCUST___T,
                    ADDR_SOLD_TO,
                    ADDR_SHIP_TO,
                    BAPC_LAST_MONTH,
                    BAPC_LAST_12_MONTHS,
                    NSR_LAST_MONTH,
                    NSR_LAST_12_MONTHS,
                    GP_LAST_MONTH,
                    GP_LAST_12_MONTHS,
                    POPULATION_LAST_MONTH,
                    POPULATION_LAST_12_MONTHS

                FROM ${process.env.REACT_APP_CARTO_TABLE_SALES_POINT} AS point
                INNER JOIN (SELECT ID AS SALES_ID, GEOMETRY FROM ${process.env.REACT_APP_CARTO_TABLE_SALES}) AS sales
                ON ST_CONTAINS(sales.geometry, point.geom)
                WHERE ${wheres.join(' AND ')}
                ORDER BY ${orders.join(',')}
                `)
                    .then(rows => {
                        e.successCallback(rows)
                    })
                    .catch(e => {
                        console.log(e)
                    })

                e.successCallback([])
            }
        }
    }, [state.showSelectedOnly, state.user, state.dateMode, state.selectedH3List])

    const onGridReady = useCallback((params) => {
        apiRef.current = params.api
        
        if (localStorage.getItem(LocalStorageKeyColumnState)) {
            params.api.applyColumnState({state: JSON.parse(localStorage.getItem(LocalStorageKeyColumnState)), applyOrder: true})
        }
        if (state.filterModelSales) {
            params.api.setFilterModel(state.filterModelSales)
        }
        setGridReady(true)
    }, [])

    const updateFilterModel = useCallback((fm) => {
        if (!gridReady) return
        setFilterModelSales(fm)
    }, [gridReady])

    const onFilterChanged = _.debounce(() => {
        updateFilterModel(apiRef.current.getFilterModel())
    }, 500)

    const onFilterModified = useCallback(() => {
        console.log("[List]", "on filter modified", apiRef.current.getFilterModel())
    })

    const onColumnStateChanged = _.debounce(() => {
        console.log("[List]", "change column state ", apiRef.current.getColumnState())
        const colDefs = apiRef.current.getGridOption("columnDefs").map(d => {
            if (d.filterParams?._type) {
                d.filterParams = d.filterParams._type
            }
            return d
        })
        localStorage.setItem(LocalStorageKeyColumnDefs, JSON.stringify(colDefs))
        localStorage.setItem(LocalStorageKeyColumnState, JSON.stringify(apiRef.current.getColumnState()))
    }, 1000)

    const columnDefs = useMemo(() => {
        return ColumnDefs.map(d => {
            switch(d.filterParams) {
                case "boolean":
                    d.filterParams = booleanFilterParams
                    break
                case "distinct":
                    d.filterParams = distinctFilterParams
                    break
                default:
                    break
            }
            d.headerTooltip = d.headerTooltip ?? d.headerName

            return d
        })

    }, [ColumnDefs])

    const onResetColumnState = () => {
        apiRef.current.resetColumnState()
    }

    const onExportExcel = () => {
        apiRef.current.exportDataAsExcel()
    }

    return (
        <Box style={styles.root}>
            <Box style={styles.controlBox}>
                <Button size="small" variant="outlined" onClick={onResetColumnState}>列初期化</Button>
                <Button size="small" variant="outlined" onClick={onExportExcel}>Excelダウンロード</Button>
            </Box>
            <Box style={styles.listBox}>
                <AgGridReact
                    ref={gridRef}
                    localeText={LOCALE_JA}
                    onGridReady={onGridReady}
                    onSortChanged={onColumnStateChanged}
                    onColumnResized={onColumnStateChanged}
                    onColumnVisible={onColumnStateChanged}
                    onColumnMoved={onColumnStateChanged}
                    onFilterChanged={onFilterChanged}
                    onFilterModified={onFilterModified}
                    className="ag-theme-balham"
                    rowModelType="infinite"
                    datasource={dataSource}
                    headerHeight={36}
                    suppressRowClickSelection={true}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    sideBar={{
                        toolPanels: [
                            {
                                id: "columns",
                                labelDefault: "列選択",
                                labelKey: "columns",
                                iconKey: "columns",
                                toolPanel: "agColumnsToolPanel",
                                toolPanelParams: {
                                    suppressRowGroups: true,
                                    suppressValues: true,
                                    suppressPivots: true,
                                    suppressPivotMode: true,
                                    suppressColumnFilter: true,
                                    suppressColumnSelectAll: true,
                                    suppressColumnExpandAll: true,
                                },
                            },
                        ],
                    }}
                />
            </Box>
        </Box>
    )
}

export default RootListSalesView
